import { createRouter, createWebHashHistory } from "vue-router"

const routes = [
  { path: "/", redirect: "/welcome" },
  {
    path : "/welcome",
    name : "welcome",
    component : ()=>import("../views/Main")
  },
  {
    path : "/voice",
    name : "voice",
    component : ()=>import("../views/voice")
  }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
export default router